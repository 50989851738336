import { ReactSession } from 'react-client-session';

import * as vars from './vars.js';

import { addDays, subDays, getDay } from 'date-fns';

async function get(endpoint, body, errors) {

	var response, data;

	var encodedBody={};
	for(const [key, value] of Object.entries(body)) {
		encodedBody[key] = encodeURIComponent(value);
	}

	var params = new URLSearchParams(encodedBody).toString();

	try {

		response = await fetch(vars.apiUrl+endpoint+'?'+params, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		});
		data = await response.json();

		//console.log(data);

	} catch(error) {

		console.log('Getting '+endpoint+'?'+params+' failed entirely.'+error);
		errors([
			{
				description: 'Getting '+endpoint+'?'+params+' failed entirely.'+error
			}]);
		return false;

	};
	

	if(data.errors) {
		console.log('Getting '+endpoint+' return errors from API.');
		for(var i=0;i<data.errors.length;i++) {
	  		console.log(data.errors[i].description);
	  	}
		errors(data.errors);
		return false;
	}

	console.log('Get '+endpoint);
	console.log(data);

	return data;

}


async function post(endpoint, body, errors) {


	var response, data;

	try {

		response = await fetch(vars.apiUrl+endpoint, {
			method: 'POST',
	      	headers: { 'Content-Type': 'application/json' },
	      	body: JSON.stringify(body),
	      	referrerPolicy: 'origin'
		});
		data = await response.json();

		//console.log(data);

	} catch(error) {

		console.log('Posting to '+endpoint+' failed entirely.'+error);
		errors([
			{
				description: 'Posting to '+endpoint+' failed entirely. '+error
			}]);
		return false;

	}

	if(data.errors) {
		console.log('Posting to '+endpoint+' returned errors from API.');
		for(var i=0;i<data.errors.length;i++) {
	  		console.log(data.errors[i].description);
	  	}
		errors(data.errors);
		return false;
	}

	console.log('Post to '+endpoint);
	console.log(data);

	return data;

}


async function validateToken() {

	ReactSession.setStoreType("localStorage");

	const queryParams = new URLSearchParams(window.location.search);
	var email = queryParams.get('account_email');
	var token = queryParams.get('account_token');

	if(!email || !token) {
		email = ReactSession.get("account_email");
		token = ReactSession.get("account_token");
	}

 
	const data = await post('/accounts/validateToken', { account_email: email, account_token: token }, (errors)=>{
		ReactSession.set("account_email", false);
		ReactSession.set("account_token", false);
		return false;
	});


	if(window.location.href == ReactSession.get("next_url")) {
		console.log('We have arrived... Clearing next_url.');
		ReactSession.set("next_reason", false);
		ReactSession.set("next_url", false);
	}


	ReactSession.set("account_email", email);
 	ReactSession.set("account_token", token);
 	return data;
}






function labelForCountryValue(value) {
  if(value=='CA') {
    return 'Canada';
  }
  return false;
}
function labelForProvinceValue(value) {
  if(value=='SK') { return 'Saskatchewan'; }
  if(value=='NB') { return 'New Brunswick'; }
  if(value=='AB') { return 'Alberta'; }
  if(value=='BC') { return 'British Columbia'; }
  if(value=='MB') { return 'Manitoba'; }
  if(value=='NL') { return 'Newfoundland and Labrador'; }
  if(value=='NWT') { return 'Northwest Territories'; }
  if(value=='NS') { return 'Nova Scotia'; }
  if(value=='NT') { return 'Nunavut'; }
  if(value=='ON') { return 'Ontario'; }
  if(value=='QB') { return 'Quebec'; }
  return false;
}

function applySaturationToHexColor(hex, saturationPercent) {
    if (!/^#([0-9a-f]{6})$/i.test(hex)) {
        throw('Unexpected color format');
    }

    if (saturationPercent < 0 || saturationPercent > 100) {
        throw('Unexpected color format');
    }

    var saturationFloat   = saturationPercent / 100,
        rgbIntensityFloat = [
            parseInt(hex.substr(1,2), 16) / 255,
            parseInt(hex.substr(3,2), 16) / 255,
            parseInt(hex.substr(5,2), 16) / 255
        ];

    var rgbIntensityFloatSorted = rgbIntensityFloat.slice(0).sort(function(a, b){ return a - b; }),
        maxIntensityFloat       = rgbIntensityFloatSorted[2],
        mediumIntensityFloat    = rgbIntensityFloatSorted[1],
        minIntensityFloat       = rgbIntensityFloatSorted[0];

    if (maxIntensityFloat == minIntensityFloat) {
        // All colors have same intensity, which means 
        // the original color is gray, so we can't change saturation.
        return hex;
    }

    // New color max intensity wont change. Lets find medium and weak intensities.
    var newMediumIntensityFloat,
        newMinIntensityFloat = maxIntensityFloat * (1 - saturationFloat);

    if (mediumIntensityFloat == minIntensityFloat) {
        // Weak colors have equal intensity.
        newMediumIntensityFloat = newMinIntensityFloat;
    }
    else {
        // Calculate medium intensity with respect to original intensity proportion.
        var intensityProportion = (maxIntensityFloat - mediumIntensityFloat) / (mediumIntensityFloat - minIntensityFloat);
        newMediumIntensityFloat = (intensityProportion * newMinIntensityFloat + maxIntensityFloat) / (intensityProportion + 1);
    }

    var newRgbIntensityFloat       = [],
        newRgbIntensityFloatSorted = [newMinIntensityFloat, newMediumIntensityFloat, maxIntensityFloat];

    // We've found new intensities, but we have then sorted from min to max.
    // Now we have to restore original order.
    rgbIntensityFloat.forEach(function(originalRgb) {
        var rgbSortedIndex = rgbIntensityFloatSorted.indexOf(originalRgb);
        newRgbIntensityFloat.push(newRgbIntensityFloatSorted[rgbSortedIndex]);
    });

    var floatToHex = function(val) { return ('0' + Math.round(val * 255).toString(16)).substr(-2); },
        rgb2hex    = function(rgb) { return '#' + floatToHex(rgb[0]) + floatToHex(rgb[1]) + floatToHex(rgb[2]); };

    var newHex = rgb2hex(newRgbIntensityFloat);

    return newHex;
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function ColorLuminance(hex, lum) {

	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '');
	if (hex.length < 6) {
		hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
	}
	lum = lum || 0;

	// convert to decimal and change luminosity
	var rgb = "#", c, i;
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i*2,2), 16);
		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
		rgb += ("00"+c).substr(c.length);
	}

	return rgb;
}

 function stringToColour(str) {
 	str = str.toString();
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return applySaturationToHexColor(ColorLuminance(colour, 0.99), 100);
  }

  function redirectUrlWithMessage(obj) {
	  var encodedObj={};
	  for(const [key, value] of Object.entries(obj)) {
	    encodedObj[key] = encodeURIComponent(value);
	  }
	  return '/message/'+JSON.stringify(encodedObj);
	}

  function hexToRgbA(hex, alpha){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+alpha+')';
    }
    throw new Error('Bad Hex');
}

function whenAccountAndInfoLoaded(whenObj, callback) {

	

	if(!whenObj.once) {
		whenObj.once = false;
	}

	var checkInterval=false;
	var checkTimes=0;



	if(!checkInterval && whenObj.once==false) {
		whenObj.once=true;
      checkInterval = setInterval(function() {
        if(whenObj.account && whenObj.info) {
        	console.log('Account and Info loaded after '+Math.round(checkTimes/vars.loadingPollingIntervalSecondsFraction*100)/100+' seconds.');
        	console.log('whenObj.info: ');
        	console.log(whenObj.info);
        	clearInterval(checkInterval);

        	// Account checks...
        	if(!whenObj.account.activated) { window.open('/activate', '_blank'); }

         	callback();
        }
        if(checkTimes > 15) {
        	console.log('Account not loaded after 6 seconds...');
       		clearInterval(checkInterval);

       		var url = redirectUrlWithMessage({
				label: 'API Error',
				description: ('Error connecting to our back-end server, please try loading this page later.'),
				redirect_url: '/',
				link_label: 'Okay',
				link_variant: 'success'
	        });
	        window.open(url, '_self');

        	//callback(false);
        }
        checkTimes++;
      }, 1000/vars.loadingPollingIntervalSecondsFraction);
    }

}


function whenAccountLoaded(whenObj, callback) {


	if(!whenObj.once) {
		whenObj.once = false;
	}

	var checkInterval=false;
	var checkTimes=0;

	if(!checkInterval && whenObj.once==false) {
		whenObj.once=true;
      checkInterval = setInterval(function() {
      	var elapsed = Math.round(checkTimes/vars.loadingPollingIntervalSecondsFraction*100)/100;
        if(whenObj.account) {
        	console.log('Account loaded after '+elapsed+' seconds.');
        	clearInterval(checkInterval);

        	// Account checks...
        	if(!whenObj.account.activated) { window.open('/activate', '_blank'); }

         	callback();
        }
        if(checkTimes > 20) {
        	console.log('Account not loaded after '+elapsed+' seconds...');
       		clearInterval(checkInterval);

       		var url = redirectUrlWithMessage({
				label: 'API Error',
				description: ('Error connecting to our back-end server, please try loading this page later.'),
				redirect_url: '/',
				link_label: 'Okay',
				link_variant: 'success'
	        });
	        window.open(url, '_self');

        	//callback(false);
        }

        checkTimes++;
      }, 1000/vars.loadingPollingIntervalSecondsFraction);
    }

}

function whenInfoLoaded(whenObj, callback) {

	if(!whenObj.once) {
		whenObj.once = false;
	}

	var checkInterval=false;
	var checkTimes=0;

	if(!checkInterval && whenObj.once==false) {
		whenObj.once=true;
      checkInterval = setInterval(function() {
      	var elapsed = Math.round(checkTimes/vars.loadingPollingIntervalSecondsFraction*100)/100;
        if(whenObj.info) {
        	console.log('Info loaded after '+elapsed+' seconds.');
        	clearInterval(checkInterval);
         	callback();
        }
        if(checkTimes > 20) {
        	console.log('Info not loaded after '+elapsed+' seconds...');
       		clearInterval(checkInterval);

       		var url = redirectUrlWithMessage({
				label: 'API Error',
				description: ('Error connecting to our back-end server, please try loading this page later.'),
				redirect_url: '/',
				link_label: 'Okay',
				link_variant: 'success'
	        });
	        window.open(url, '_self');

        	//callback(false);
        }
        checkTimes++;
      }, 1000/vars.loadingPollingIntervalSecondsFraction);
    }

}

function dateToYMD(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = date.getFullYear();
    return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

function serviceStatusColourFromSubscription(sub) {

	var status = sub.status.value;


	if(status=='installation_period') {
		return '#00FF00';
	} else if(status=='installation_period_cancelling') {
		return '#FFFF00';
	} else if(status=='active') {
		return '#00FF00';
	} else if(status=='active_billing_today') {
		return '#00FF00';
	} else if(status=='paused') {
		return '#FF0000';
	} else if(status=='cancelled') {
		return '#FF0000';
	} else if(status=='active_cancelling') {
		return '#FFFF00';
	} else if(status=='active_pausing') {
		return '#FFFF00';
	} else if(status=='paused_resuming') {
		return '#FF0000';
	} else if(status=='cancelled_resuming') {
		return '#FF0000';
	} else if(status=='deactivated') {
		return '#FF0000';
	}



	return '#000000';

}

function billedThroughDateFromSubscription(sub) {

	var status = sub.status.value;

	// installation_period: { value: 'installation_period', name: 'Installation Period', desc: 'Subscription starts soon, service is being installed.' },
	// installation_period_cancelling: { value: 'installation_period_cancelling', name: 'Installation Period, Cancelling', desc: 'Subscription cancellation is scheduled following the installation period.' },

	// active: { value: 'active', name: 'Active', desc: 'Subscription active.' },
	// paused: { value: 'paused', name: 'Paused', desc: 'Subscription paused.' },
	// cancelled: { value: 'cancelled', name: 'Cancelled', desc: 'Subscription cancelled.' },

	// active_cancelling: { value: 'active_cancelling', name: 'Active but Cancelling', desc: 'Subscription is active, but will be cancelled after the current period.' },
	// active_pausing: { value: 'active_pausing', name: 'Active but Pausing', desc: 'Subscription his active but will be paused soon.' },

	// paused_resuming: { value: 'paused_resuming', name: 'Active but Pausing', desc: 'Subscription his active but will be paused soon.' },
	// cancelled_resuming: { value: 'cancelled_resuming', name: 'Cancelled and Inactive', desc: 'Subscription is inactive and cancelled.' }





	if(status=='installation_period') {
		return 'Billing begins '+sub.square_subscription.start_date;
	} else if(status=='installation_period_cancelling') {
		return 'Cancelling, no next bill.';
	} else if(status=='active') {
		return 'Next bill is '+sub.square_subscription.charged_through_date;
	} else if(status=='active_billing_today') {
		return 'Next bill is today.';
	} else if(status=='paused') {
		return 'Paused, no next bill.';
	} else if(status=='cancelled') {
		return 'Cancelled, no next bill.';
	} else if(status=='active_cancelling') {
		return 'Cancelling, no next bill.';
	} else if(status=='active_pausing') {
		return 'Pausing, no next bill.';
	} else if(status=='paused_resuming') {
		return 'Paused, next bill is on '+sub.resuming_date;
	} else if(status=='cancelled_resuming') {
		return 'Cancelled, next bill is on '+sub.resuming_date;
	} else if(status=='deactivated') {
		return 'Deactivated, no next bill.';
	}

	

}


function serviceAction(service) {

	if(!service.pausing_date && !service.resuming_date && !service.cancelling_date && service.status.value=='installation_period') {
		return 'Service starts on '+service.square_subscription.start_date;
	}

	if(service.pausing_date && service.status.value!='paused') {
		return 'Service is pausing on '+service.pausing_date;
	}
	if(service.resuming_date && service.status.value!='active') {
		return 'Service is resuming on '+service.resuming_date;
	}
	if(service.cancelling_date && service.status.value!='cancelled') {
		return 'Service is cancelling on '+service.cancelling_date;
	}
	if(service.square_subscription.charged_through_date && !service.pausing_date && !service.resuming_date && !service.cancelling_date) {
		return 'Service charged through '+service.square_subscription.charged_through_date;
	}



	return '';
}


function getTotalForServiceOrder(order, packages) {

	//console.log(packages);


  var total=0;


  for(var j=0;j<order.receipts.length;j++) {
    var aReceipt = order.receipts[j];

    for(var i=0;i<aReceipt.length;i++) {
      var item = aReceipt[i];
      if(item.data && item.data.package_id > 0) {
      } else if(item.data && item.data.package_id==0) {
        total = total + item.price;
      
      } else if(item.value=='basic_installation') {
        total = total + item.price;
 
      } else if(item.value=='preorder_credit') {
        total = total - 99;
 
      }
      
    }

  }

  

  var taxedTotal = total*1.11;

  return Math.round(taxedTotal*100)/100;

}

function numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
}

function packageWhereKeyIsValue(packages, key, value) {

	for(var i=0;i<packages.length;i++) {
		var aPackage = packages[i];
		if(aPackage[key] == value) {
			
			return aPackage;

		}
	}
	return false;
}

function consoleLog(obj) {
  console.log(JSON.stringify(obj, null, 2));
}

const isWeekdayOrPassed = (date) => {
    const day = getDay(date);

    const currentDate = new Date();
    const selectedDate = date;

    return day !== 0 && day !== 6 && selectedDate.getTime() >= currentDate.getTime();
  };


export {
	serviceAction,
	whenAccountAndInfoLoaded,
	whenInfoLoaded,
	whenAccountLoaded,
	billedThroughDateFromSubscription,
	redirectUrlWithMessage,
	serviceStatusColourFromSubscription,
	hexToRgbA,
	stringToColour,
	isWeekdayOrPassed,
	consoleLog,
	packageWhereKeyIsValue,
	numberWithCommas,
	labelForCountryValue,
	labelForProvinceValue,
	getTotalForServiceOrder,
	validateToken,
	get,
	post
}