import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import LmwForm from './LmwForm';

import * as vars from './../vars.js';
import * as funcs from './../funcs.js';

var parseOnce=false;
function Contact(props) {

  ReactSession.setStoreType("localStorage");

  const [errors, setErrors] = useState([]);




   useEffect(() => {
    if(!parseOnce) {
      parseOnce=true;



    }
  });

  return (
    <div className="page">
      <div className="text_block_white" style={{textAlign: "center"}}>
        <h1>Get in touch</h1><br/>
        <div style={{minWidth: "200px", width: "50%", display: "inline-block", textAlign: "center"}}>
        
        <p className="black_link"><a  href="tel://1-306-900-4321"><img src="img/phone_icon_black.png" width="100" /></a></p>
        <h3 className="black_link"><a  href="tel://1-306-900-4321">1.306.900.4321</a></h3>
        <h6>Call or text</h6>
        </div>

        <div style={{minWidth: "200px", width: "50%", display: "inline-block", textAlign: "center"}}>
        
        <p className="black_link"><a  href="mailto:info@lmw.email"><img src="img/mail_icon_black.png" width="100" /></a></p>
        <h3 className="black_link"><a  href="mailto:info@lmw.email">info@lmw.email</a></h3>
        <h6>Send an Email</h6>
        </div>
      </div>
      <div className="text_block_blue" style={{textAlign: "center"}}>

         <LmwForm  
         theme="dark"
         id="contact_us"
         nextReason="To contact us"
          submitLabel="Contact Us" submitAction={"/contactUs"}
          backLabel="" backAction="" 
          buttonSize="lg" 
          fields={[

          { type: 'spacer', label: 'Message us Online'},

          { type: 'text', name: 'name', label: 'Name',  placeholder: 'John Smith', defaultValue: '', readOnly: false},
          { type: 'text', name: 'email_or_phone', label: 'Email Or Phone Number',  placeholder: 'Leave us an email or phone # you can be reached at.', defaultValue: '', readOnly: false},         
          { type: 'textarea', name: 'message', label: 'Message',  placeholder: 'Enter your message to us here.', defaultValue: '', readOnly: false, textareaArgs: {rows: 5}},
          { type: 'spacer_short'},
  

        ]} />


      </div>

    </div>);




}
  
export default Contact;