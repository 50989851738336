import React, {useState, useEffect} from 'react';

import { Link, useParams } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import ReceiptTable from './ReceiptTable';
import Loading from './Loading';
import PleaseLogin from './PleaseLogin';

import Button from 'react-bootstrap/Button';


import * as vars from './../vars.js';
import * as funcs from './../funcs.js';


function consoleLog(obj) {
  console.log(JSON.stringify(obj, null, 2));
}

var whenObj = {};
function Order(props) {

  let { number } = useParams();

  ReactSession.setStoreType("localStorage");

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(false);
  const [render, setRender] = useState(0);


  useEffect(() => {

    whenObj.account = props.account;
    whenObj.info = props.info;

    funcs.whenAccountAndInfoLoaded(whenObj, async function() {
  

    const orderData = await funcs.get('/orders/'+number, {
        account_token: ReactSession.get("account_token"),
        account_email: ReactSession.get("account_email")
      }, (errors)=>{});

    setLoading(false);
    setOrder(orderData);

    });

    
  });

  if(loading) { return (<><Loading /></>); }
  if(!props.account || !order) { return (<><PleaseLogin /></>); }



  return (
    <div className="page">
      <div className="text_block_white block_text_align_center">

          <div className="form_left">
            <h1>Order #{order.number}</h1>
            



            <div className={order.type=='service'?'':'hidden'}>
            <ReceiptTable label="Installation Invoice" receipts={order.receipts[1]} render={render} />
            </div>

            <div className={order.type=='preorder'?'':'hidden'}>
              <ReceiptTable label="Pre-order Invoice" receipts={order.receipts[0]} render={render} />
            </div>


            <h5>Status: {props.info.orderStatuses?props.info.orderStatuses[order.status].name:''}</h5>
            <div className="p_after_h">{props.info.orderStatuses[order.status]?props.info.orderStatuses[order.status].desc:''}</div><br/>
            
            <span className={order.type=='preorder'?'':'hidden'}><p>Has been applied: {order.preorder_applied_to_order?order.preorder_applied_to_order.number:'No'}</p></span>

            <Button variant="warning" size="lg" onClick={()=>{ window.open('/dashboard', '_self'); }}>
              Back to Dashboard
            </Button>

            <div className={order.type=='preorder'?'inline':'hidden'}>
              <Button className={"shift_back_button_on_form "+((order.status=='paid' && !order.preorder_applied_to_order)?'':'hidden')} variant="danger" size="lg" onClick={()=>{ 


              var url = funcs.redirectUrlWithMessage({
                  label: 'Cancel and Refund Pre-order',
                  description: ('This will cancel and refund your preorder if it has not yet been applied. Are you sure you want to cancel?'),
                  redirect_url: vars.apiUrl+'/orders/refundPreorder?account_token='+ReactSession.get("account_token")+'&account_email='+ReactSession.get("account_email")+'&order_number='+order.number,
                  link_label: 'Yes',
                  link_variant: 'success',
                  redirect2_url: '/orders/'+order.number,
                  link2_label: 'No',
                  link2_variant: 'danger'
              });

              window.open(url, '_self');

            }}>
              Cancel Pre-order
            </Button>
            </div>

          </div>

      </div>

    </div>);

}
  
export default Order;
